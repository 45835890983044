import './fastspin.css';

function FastSpin(){
    return(
        <>


        <div>
            <div className='spinner'>

            </div>
            <p className='sam_abs' style={{color:'#fff', fontSize:'15.6px', wordSpacing:'1px',letterSpacing:'1px'}}>Setting up now...</p>

        </div>


        </>
    )
};

export default FastSpin;